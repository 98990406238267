<template>
    <HowItWorksShowcaseBlock v-editable="blok" :data="howItWorksShowcase" />
</template>

<script setup lang="ts">
import HowItWorksShowcaseType from '@types/HowItWorksShowcaseType';
import HowItWorksShowcaseCardType from '@types/HowItWorksShowcaseCardType';
import ButtonType from '@types/ButtonType';
import HowItWorksShowcaseBlock from '~/components/page-building/resources/how-it-works-showcase/HowItWorksShowcaseBlock.vue';
import ImageType from '~/types/ImageType';
import LinkType from '~/types/LinkType';

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
    nextBlock: {
        type: Object,
        required: false,
        default: null,
    },
});

const howItWorksShowcase = computed<HowItWorksShowcaseType>(() => {
    const buttons = props.blok.buttons
        ? props.blok.buttons.map((button: any) => {
              return {
                  label: button.label,
                  style: button.style,
                  url: button.link.cached_url,
                  vEditable: button,
              } as ButtonType;
          })
        : [];

    const cards = props.blok.cards
        ? props.blok.cards.map((card: any) => {
              return {
                  image:
                      card.image && card.image.filename
                          ? ({
                                title: card.image.title,
                                alt: card.image.alt,
                                src: card.image.filename,
                            } as ImageType)
                          : null,
                  icon:
                      card.icon && card.icon.filename
                          ? ({
                                title: card.icon.title,
                                alt: card.icon.alt,
                                src: card.icon.filename,
                            } as ImageType)
                          : null,
                  hoverIcon:
                      card.hover_icon && card.hover_icon.filename
                          ? ({
                                title: card.hover_icon.title,
                                alt: card.hover_icon.alt,
                                src: card.hover_icon.filename,
                            } as ImageType)
                          : null,
                  vEditable: card,
              } as HowItWorksShowcaseCardType;
          })
        : [];

    return {
        heading: props.blok.heading,
        description: props.blok.description,
        cards,
        buttons,
        backgroundColor: props.blok.background_color,
        nextBackgroundColor: props.nextBlock
            ? props.nextBlock.background_color
            : null,
        bottomAngle: props.blok.bottom_angle,
        arrowCallout: props.blok.arrow_callout_type
            ? {
                  type: props.blok.arrow_callout_type,
                  text: richTextToHtml(props.blok.arrow_callout_text) as string,
              }
            : null,
        reverseLayout: props.blok.reverse_layout,
    } as HowItWorksShowcaseType;
});
</script>
